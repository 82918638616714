import moment from 'moment-timezone';
// handle all time variable conversion, processing,...

class TimeHelper {
  tz='Europe/London'
  m = (...args) => moment(...args).tz(this.tz);
  format(t : any, f = 'DD/MM/YYYY', parsedInputFormat = undefined) {
    if (this.m(t, parsedInputFormat).isValid() === false) return t;
    return this.m(t, parsedInputFormat).format(f);
  }

  isAfter = (time, timeFormat = undefined) => {
    let now = this.m();
    let deadline = this.m(time, timeFormat);
    return now.isAfter(deadline);
  }

  compare = (time1, time2, timeFormat = undefined) => {
    let t1 = this.m(time1, timeFormat);
    let t2 = this.m(time2, timeFormat);
    return t1.isAfter(t2) ? 1 : -1;
  }
}

export default new TimeHelper();
